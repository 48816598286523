:root { 
  --text-color: #212427;
  --yellow: #FFE383;
  --purple: #877BE7;
  --pink: #FF8E94;
  --darkgrey: #757678;
  --grey: #F0F0F0;
  --lightgrey: #E5E5E5;
}

/* #grid {
  display: block;
} */

.project-container {
  flex-direction: row;
  display: flex;
  margin-top: 188px;
  margin-bottom: 200px;
  justify-content: center;
  align-items: center;

}

.apartly-banner {
  align-self: center;
  margin-left: 120px;

}

.apartly-banner img {
  width: 600px
}

.button-block {
  flex-direction: column;
  align-items: flex-start;
display: flex;
}

.project-heading {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 24px;
}

.button-content {
  flex-direction: row;
  display: flex;
}

.proto-button {
  border: 1px solid lightgrey;
  border-radius: 0.5rem;
  padding: 12px;
  margin-bottom: 16px;
  text-decoration: none;
  font-weight: 500;
}

.proto-button:hover {
  background-color: var(--grey);
}

.proto-button img {
  margin-right: 8px;
}


#coming-soon {
  font-size: 10px;
  font-weight: 500;
  margin-bottom: 52px;
  color: #878787;
}


#thats-me {
  font-size: 20px;
  font-weight: 400;
}

.work-about {
  display: flex;
  gap: 8rem;
}

#job-title {
  font-size: 16px;
  /* font-weight: bold; */
}

#header {
  display: flex;
  align-self: center;
}


.nav {
  display: flex;
  justify-content: space-between;
}

.nav ul {
  list-style: none;
  font-size: 16px;
}

.nav a {
  text-decoration: none;
  color: var(--text-color);
}


.avatar {
  height: 48px;
  width: 48px;
  display: inline-block;
  margin-right: 16px;
  align-self: center;
}


.bold {
  font-weight: 400;
}

.purple {
  color: var(--purple);
}

.darkgrey {
  color: var(--darkgrey);
}

.turquoise {
  color: #7DD5C5;
}

.yellow {
  color: var(--yellow);
}

.active {
  font-weight: normal;
}

.nav-item .active::after {
  content: "";
  position: absolute;
  width: 30%; 
  height: 2px;
  left: 10px; 
  bottom: 1px;   
  background-color: var(--text-color);
}

/* .nav-li {
  padding: 10px;
  position: relative;
} */


.nav-item {
  padding: 10px;
  position: relative;
}

.nav-item a:hover {
  font-weight: normal;
}

.nav-item:hover::after {
  width: 30%;
}

.nav-item::after {
  content: "";
  position: absolute;
  width: 0; 
  height: 2px;
  left: 10px; 
  bottom: 1px;   
  background-color: var(--text-color);
  transition: 0.3s ease-in-out; 
}

.nav-right {
  float: right;
}

 /* .content {
  min-height: 80%;
} */

p {
  font-size: 16px;
  line-height: 150%;
}

h1 {
  margin: 0rem;
}

h4 {
  margin: 0px;
  font-weight: bold;
  font-size: 14px;
  /* color: #757678; */
  /* font-family: 'Droid Serif'; */
}

.about-margin {
  margin: 40px 0px 0px 0px;
}

body {
  margin: 4rem 6rem 0px 6rem;
  font-family: europa,sans-serif;
  font-weight: lighter;
  font-style: normal;
  color: var(--text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content {
  margin-top: 8rem;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}

.font-size-14 {
  font-size: 14px;
}

.margin-top-48 {
  margin-top: 48px;
}

.margin-top-32 {
  margin-top: 32px;
}

.font-size-12 {
  font-size: 12px;
}

.company {
  /* color: grey; */
  font-size: 14px;
  font-weight: bold;
}

.title {
  margin-top: 8px;
  font-size: 18px;
  color: var(--darkgrey);
  font-weight: normal;
}

.blue {
  color: #90B7F4;
}

/* .flex {
display: inline-flex;


} */

.float {
  display: flex;
  flex-direction: row;
}

.gap-10 {
  gap: 10rem;
}

.project-description {
  width: 60%;
}

.project-role {
  margin-top: 0px;
  margin-bottom: 0px;
}

#quote {
text-align: center;
margin: 48px 0px 48px 0px;
}

#quote-phrase {
  font-family: 'Droid Serif';
font-weight: normal;
font-size: 16px;

}

#quote-author {
  padding: 10px;
  font-size: 10px;
  font-weight: bold;
}


#work-info {
  width: 100%;
  margin-top: 88px;
}

#introduction {
width: 50%;
}

#gradient {
  position: absolute;
  left: 200px;
  top: 40px;
  width: 600px;
  z-index: -1;
}

a {
color: var(--text-color);
}


.services {
  width: 25%;
  font-size: 16px;
  
}

.services ul {
  list-style: none;
  padding-left: 0px;
}

.services ul li {
  padding-bottom: 8px;
}

/* .project-item {
  /* background: #F5F4F4; */
  /* transition: var(--transition);
  box-shadow: 0px 1px 6px #DDDDDD; */
  /* height: 26rem; */




/* .project-item-banner {
  text-decoration: none;
  width: 100%;
  height: 30%;
  

  
} */

/* .project-item-text {
  padding: 1.3rem 0% 0% 0%; 
  width: 80%;
  margin: 0% auto;
} */

.project-link {
  text-decoration: none;
}

.project-image {
  width: 100%;
  height: 70%;
  object-fit: cover;
  box-shadow: 0px 1px 6px #DDDDDD; 

}




#about {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 8rem;

}

#work-header {
font-weight: normal;
/* font-family: 'Droid Serif'; */
font-size: 22px;
}

#work-hello {
  font-weight: 600;
  font-size: 36px;
}

#work-intro {
  font-family: Avenir Next, sans-serif;
  margin: auto;
  width: 660px;
} 


#work-statement {
  font-family: europa, sans-serif;
  font-weight: lighter;
  margin: 0px 0px 40px 0px;
  font-size: 30px;
}

#statement-underline {
  font-size: 20px;
  font-weight: 600;
}

#work-subline {
  font-size: 12px;
  font-weight: 600;
  color: var(--purple);
  margin: 0px 0px 164px 0px;
}

#about-title {
  font-weight: normal;
  font-size: 32px;
  /* font-family: 'Droid Serif'; */
}

#about-text {
  font-size: 18px;
}



#portrait {
  width: 70%;
  display: block;
  margin: auto;
}

#selfmade {
font-weight: lighter;
font-size: 12px;
padding-top: 8px;
}

 #heart {
  vertical-align: middle;
 } 

#footer {
  position: relative;
  bottom: 0;
  padding: 104px 0px 32px 0px;
  font-size: 14px;
}

#footer div {
  justify-content: center;
}

.menu {
  display: none;
}

.socials {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.socials a {
  padding: 6px;
}

.footer-flex {
  display: flex;
  text-align: center;
  align-items: center;
}

.center {
  text-align: center;
  align-self: center;
}

.background {
background: linear-gradient(180deg, white 50%, var(--yellow) 50% );
display: inline-block;
}

.statement-background {
  background: linear-gradient(180deg, white 50%, var(--yellow) 50% );
  /* width: 170px; */
  }

  #spexor {
    margin-top: 3rem;
  }

  .nda {
    font-size: 10px;
    font-weight: bold;
  }

  .project-list {
    list-style: none;
  }

  .project-list li {
    padding-bottom: 8px;
    width: 100%;
  }

  .width-30 {
    width: 30%;
    align-self: center;
  }

  .width-70 {
    width: 70%;
  }

  .project-img {
    width: 100%;
  }

/* .yellow-background {
  content: "";
  position: relative;
  width: 20px;
  height: 10px;
  bottom: 10px; 
  background-color: var(--yellow);
} */

@media screen and (max-width: 1300px) {
.work-about {
  gap: 1rem;
}

#introduction {
  margin-right: 1rem;
}


}

@media screen and (max-width: 1920px) {
  #gradient {
    left: 400px;
  }
}

@media screen and (max-width: 1536px) {
  #gradient {
    left: 210px;
  }
}

@media screen and (max-width: 1366px) {
  #gradient {
    left: 140px;
  }
}


@media screen and (max-width: 1024px) {

  #gradient {
    left: 0px;
    width: 600px;
  }

  body {
    margin: 4rem 4rem 0px 4rem;
  } 

  .content {
    margin-top: 6rem;
  }

  #spexor {
    margin-top: 3rem;
  }


#project-area {
  grid-template-columns: 1fr;
  gap: 1.2rem;
}

#about {
  grid-template-columns: 1fr;
  gap: 1rem;
}

.work-about {
  flex-direction: column;
  gap: 1.5rem;
}

#introduction {
  width: 100%;
}

.services {
  width: 100%;
}

.services-margin {
  margin-top: 24px;
}

#about-image {
  margin-top: 32px;
}




}



@media screen and (max-width: 768px) {

.project-container {
  flex-direction: column-reverse;

}

.apartly-banner {
  margin-left: 0px;
  margin-bottom: 48px;
}

.apartly-banner img {
  width: 400px;
}

  #gradient {
    top: 65px;
    left: 50px;
    width: 400px;
  }
  
  body {
    margin: 48px 2rem 0% 2rem;
  }

  #work-intro {
    margin: auto;
    width: 300px;
  } 

  #thats-me {
    font-size: 16px;
  }

  #job-title {
    font-size: 14px;
    /* font-weight: bold; */
  }

  .overlay {
background: white;
position: fixed;
top: 75px;
left: 0;
width: 100%;
height: 150px; 


  }

  #hamburger-visibility {
    position: absolute;
    right: 96px;
  }


  .menu {
    display: none;
    text-align: center;
    padding: 40px 0px 0px 0px;
  }


  .menu.expanded {
    display: block;
    
  }

  .nav {
    width: 100%;
    background: white;
    position: fixed;
    top: 0px;
    left: 0;
    padding: 32px;
  }

  .nav-item::after {
    visibility: hidden;
  }

  .nav-item .active::after {
    visibility: hidden;
  }

  /* .nav-item .active::after {
    content: "";
    position: absolute;
    width: 20px; 
    height: 2px;
    left: 310px; 
    bottom: 1px;   
    background-color: var(--text-color);
  } */
/* 
  .nav-item::after {
    content: "";
    position: absolute;
    width: 0; 
    height: 2px;
    left: 310px; 
    bottom: 1px;   
    background-color: var(--text-color);
    transition: 0.3s ease-in-out; 
  }


  .nav-item:hover::after {
    width: 20px;
  } */

  .desktop {
    display: none;
  } 


  #hamburger {
    cursor: pointer;
    position: relative;
    top: 16px;
    
  }


  #line-1 {
  content: "";
  position: relative;
  width: 25px; 
  height: 2px;
  bottom: 0px;   
  background-color: var(--text-color);
  }

  #line-2 {
    content: "";
    position: relative;
    width: 20px; 
    height: 2px;
    margin-top: 4px;   
    background-color: var(--text-color);
    margin-left: 5px;

    }

  #line-3 {
      content: "";
      position: relative;
      width: 15px; 
      height: 2px;
      margin-top: 4px;   
      background-color: var(--text-color);
      margin-left: 10px;
  
      }

  #work-hello {
    font-size: 26px;
  }

  #work-statement {
    font-size: 22px;
  }

  #work-subline {
    font-size: 12px;
    margin: 0px 0px 96px 0px;
  }

  #statement-underline {
  font-size: 16px;
  }


  .project-item-banner {
    height: 30%;  
  }
  
  .project-image {
    height: 70%;
  
  }

  #quote {
    margin-bottom: 32px;
  }

  #quote-phrase {
    font-size: 14px;
  }

  .content {
    margin-top: 12rem;
  }

  #about {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  #spexor {
    margin-top: 8rem;
  }

  


  .footer-flex {
    flex-direction: column;
  }

}

@media screen and (max-width: 414px) {

  #gradient {
    top: 80px;
    left: 0px;
    width: 380px;
  }

}